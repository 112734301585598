import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const AnchorBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} blockPadding="py-0">
      <div id={block.target_hash}></div>
      {typeof window !== "undefined" && window.storyblok?.inEditor && (
        <div
          className="py-2 mb-8 rounded px-grid"
          style={{ backgroundColor: "#09b3af" }}
        >
          <p className="font-medium prose text-white">
            <h3 style={{ color: "#ffffff" }}>Anker Block:</h3>
            Hier wird ein Anker mit der Bezeichung <b>
              "{block.target_hash}"
            </b>{" "}
            gesetzt.
          </p>
        </div>
      )}
    </BlockWrapper>
  )
}

export default AnchorBlock
